import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { current, produce } from "immer";
import cloneDeep from "lodash.clonedeep";

const initStore = {
    user: {
        id: null,
        role: null,
        email: null,
    },
};

export const useUserStore = create((set, get) => ({
    ...cloneDeep(initStore),

    methods: {
        setUser: user => {
            set(
                produce(state => {
                    state.user = user;
                })
            );
        },

        unsetUser: () => {
            set(
                produce(state => {
                    state.user = {
                        id: null,
                        role: null,
                        email: null,
                    };
                })
            );
        },

        reset: () => set(cloneDeep(initStore)),
    },
}));
