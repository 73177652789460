export const localPaths = {
    home: "",
    intro: "intro",
    chatbotservice: "chabotservice",
    prices: "prices",
    docs: "docs",
    help: "help",
    addChatbotEnquiry: "chatbot-enquiry",
    joinWaitlist: "join-waitlist",
    // solutions: "solutions",
    login: "login",
    signup: "signup",
};
