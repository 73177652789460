import { create } from "zustand";
import { produce } from "immer";
import cloneDeep from "lodash.clonedeep";

const initStore = {
    project: {
        _id: localStorage.getItem("project._id"),
        name: localStorage.getItem("project.name"),
        org: localStorage.getItem("project.org"),
    },
    org: {
        _id: localStorage.getItem("org._id"),
        name: localStorage.getItem("org.name"),
    },
};

export const useProjectStore = create((set, get) => ({
    ...cloneDeep(initStore),

    methods: {
        setProject: (project, org) => {
            set(
                produce(state => {
                    state.project = project;
                    state.org = org;
                })
            );

            localStorage.setItem("project._id", project._id);
            localStorage.setItem("project.name", project.name);
            localStorage.setItem("project.org", project.org);
            localStorage.setItem("org._id", org._id);
            localStorage.setItem("org.name", org.name);
        },

        clearProject: () => {
            set(
                produce(state => {
                    state.project = {
                        _id: null,
                        name: null,
                        org: null,
                    };
                    state.org = {
                        _id: null,
                        name: null,
                    };
                })
            );

            localStorage.removeItem("project._id");
            localStorage.removeItem("project.name");
            localStorage.removeItem("project.org");
            localStorage.removeItem("org._id");
            localStorage.removeItem("org.name");
        },
    },
}));
