import axios from "axios";
import { ILogEntry, ILogData, LogLevel } from "@mirinae/apps/shared/types/logging";

export interface ILogServiceConfig {
    apiEndpoint?: string;
    batchSize?: number;
    batchTimeoutMs?: number;
}

const consoleFormat = {
    colors: {
        info: "#2196F3", // blue
        warn: "#FF9800", // orange
        error: "#F44336", // red
        debug: "#9E9E9E", // gray
    },

    getTimestamp(): string {
        return new Date().toLocaleTimeString("en-US", {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
    },

    getLevelIcon(level: LogLevel): string {
        switch (level) {
            case "info":
                return "ℹ️";
            case "warn":
                return "⚠️";
            case "error":
                return "🚫";
            case "debug":
                return "🔧";
        }
    },

    printFormattedLog(level: LogLevel, message: string, data?: ILogData): void {
        const timestamp = this.getTimestamp();
        const icon = this.getLevelIcon(level);
        const color = this.colors[level];

        const styles = [`color: ${color}`, "font-weight: bold", "padding: 2px 0"].join(";");

        console.groupCollapsed(`%c${timestamp} ${icon} ${message}`, styles);

        if (data) {
            const contextData = level === "debug" ? data : this.filterStandardContext(data);

            if (Object.keys(contextData).length > 0) {
                console.log("Context:", contextData);
            }
        }

        if (level === "error" && data?.stack) {
            console.log("Stack trace:");
            console.trace(data.stack);
        }

        console.groupEnd();
    },

    filterStandardContext(data: ILogData): Partial<ILogData> {
        const { userAgent, url, ...rest } = data;
        return rest;
    },
};

class LogService {
    /* Sends logs to the client-facing log proxy, which handles log ingestion for Seq */

    protected readonly apiEndpoint: string;
    protected readonly batchSize: number;
    protected readonly batchTimeoutMs: number;
    private logQueue: ILogEntry[];
    private timer: number | null;
    private context: ILogData;

    constructor(config: ILogServiceConfig, context: ILogData = {}) {
        this.apiEndpoint = config.apiEndpoint;
        this.batchSize = config.batchSize || 10;
        this.batchTimeoutMs = config.batchTimeoutMs || 5000;
        this.logQueue = [];
        this.timer = null;
        this.context = context;
    }

    public child(childContext: ILogData): LogService {
        return new LogService(
            {
                apiEndpoint: this.apiEndpoint,
                batchSize: this.batchSize,
                batchTimeoutMs: this.batchTimeoutMs,
            },
            { ...this.context, ...childContext }
        );
    }

    private async sendLogs(logs: ILogEntry[]): Promise<void> {
        if (this.apiEndpoint == null) {
            return;
        }

        try {
            await axios.post(this.apiEndpoint, logs, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
        } catch (error) {
            console.error("Failed to send logs:", error);
        }
    }

    private processQueue(): void {
        if (this.logQueue.length === 0) return;

        const logsToSend = this.logQueue.splice(0, this.batchSize);
        void this.sendLogs(logsToSend);
    }

    private startTimer(): void {
        if (!this.timer) {
            this.timer = window.setInterval(() => {
                this.processQueue();
            }, this.batchTimeoutMs);
        }
    }

    queueLog(entry: ILogEntry): void {
        // always print to console as well
        consoleFormat.printFormattedLog(entry.level as LogLevel, entry.message, entry.data);
        this.logQueue.push(entry);

        if (this.logQueue.length >= this.batchSize) {
            this.processQueue();
        }

        this.startTimer();
    }
}

export { LogService };
