import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { logger } from "@hyperflow/modules/logging/logger";

import languages from "../lang";

export const DEFAULT_LANGUAGE = "ko";

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ["querystring", "localStorage", "navigator", "htmlTag", "path", "subdomain"],
            caches: ["localStorage"],
            lookupLocalStorage: "language",
            lookupQuerystring: "lang",
        },
        fallbackLng: DEFAULT_LANGUAGE,
        defaultNS: "main",
        interpolation: {
            escapeValue: false,
        },
        load: "languageOnly",
        ns: ["main"],
        react: {
            useSuspense: false,
        },
        returnEmptyString: false,
        returnNull: false,
    });

logger.info("i18next.language", { language: i18next.language }); // Logs the detected language (e.g., "en" or "fr"

Object.keys(languages).forEach(lng => {
    i18next.addResourceBundle(lng, "main", languages[lng], true, true);
});

export default i18next;
