import { useEffect } from "react";
import { create } from "zustand";
import type { ILogData } from "@mirinae/apps/shared/types/logging";
import { ILogEntry, LogLevel } from "@mirinae/apps/shared/types/logging";
import { LogService, ILogServiceConfig } from "./service";

// stores we always "subscribe" to
import { useProjectStore } from "@mirinae/apps/hyperflow/modules/stores/project";
import { useUserStore } from "@mirinae/apps/shared/stores/user";

interface ILoggerState {
    context: ILogData;
    setContext: (updates: ILogData) => void;
    clearContext: (keys: string[]) => void;
}

export const useLoggerStore = create<ILoggerState>((set, get) => ({
    context: {},
    setContext: (updates: ILogData) =>
        set(state => ({
            context: { ...state.context, ...updates },
        })),
    clearContext: (keys: string[]) =>
        set(state => {
            const newContext = { ...state.context };
            keys.forEach(key => delete newContext[key]);
            return { context: newContext };
        }),
}));

// non-react context manager
export const LoggingContext = {
    withContext: <T>(values: ILogData, fn: () => T): T => {
        const store = useLoggerStore.getState();
        store.setContext(values);
        try {
            return fn();
        } finally {
            store.clearContext(Object.keys(values));
        }
    },

    withAsyncContext: async <T>(values: ILogData, fn: () => Promise<T>): Promise<T> => {
        const store = useLoggerStore.getState();
        store.setContext(values); // this is NOT really a replacement for AsyncLocalStorage since this is not promise-chain local at all.
        try {
            return await fn();
        } finally {
            store.clearContext(Object.keys(values));
        }
    },

    get: () => useLoggerStore.getState().context,
};

// react lifecycle hook management
export function useLoggingContext(contextValues: ILogData) {
    const { setContext, clearContext } = useLoggerStore();

    useEffect(() => {
        setContext(contextValues);
        return () => clearContext(Object.keys(contextValues));
    }, [JSON.stringify(contextValues)]);
}

export class ContextLogger {
    private instanceContext: ILogData;
    private service: LogService;
    private config: ILogServiceConfig;

    constructor(config: ILogServiceConfig, context: ILogData = {}) {
        this.instanceContext = context;
        this.service = new LogService(config);
        this.config = config; // for children
    }

    public initializeErrorHandling(): void {
        window.onerror = (message: string | Event, source?: string, lineno?: number, colno?: number, error?: Error): void => {
            this.error("Uncaught Error", {
                message: message.toString(),
                source,
                lineno,
                colno,
                stack: error?.stack,
            });
        };

        window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent): void => {
            this.error("Unhandled Promise Rejection", {
                reason: event.reason,
                stack: event.reason?.stack,
            });
        });
    }

    public info(message: string, data?: ILogData): void {
        const logEntry = this.createLogEntry("info", message, data);
        this.service.queueLog(logEntry);
    }

    public warn(message: string, data?: ILogData): void {
        const logEntry = this.createLogEntry("warn", message, data);
        this.service.queueLog(logEntry);
    }

    public error(message: string, data?: ILogData): void {
        const logEntry = this.createLogEntry("error", message, data);
        this.service.queueLog(logEntry);
    }

    public debug(message: string, data?: ILogData): void {
        const logEntry = this.createLogEntry("debug", message, data);
        this.service.queueLog(logEntry);
    }

    child(childContext: ILogData): ContextLogger {
        return new ContextLogger(this.config, { ...this.instanceContext, ...childContext });
    }

    private gatherGlobalContext(): ILogData {
        // gather user, project and app store data that is globally relevant - these
        // are necessarily singleton data stores (excluding those parameterized ones
        // of which there may be many - use some sort of context manager with a namespace
        // argument in that case if needed)
        const { project, org } = useProjectStore.getState();
        const { user } = useUserStore.getState();

        const filterMissingState = data => {
            // because Seq is sensitive to existent, but undefined/null values
            return Object.fromEntries(Object.entries(data).filter(([_, value]) => value !== null && value !== undefined));
        };

        return filterMissingState({
            projectId: project?._id?.toString(),
            orgId: org?._id?.toString(),
            userId: user?.id,
            userEmail: user?.email,
        });
    }

    createLogEntry(level: LogLevel, message: string, data: ILogData): ILogEntry {
        const logContext = useLoggerStore.getState().context;
        const globalContext = this.gatherGlobalContext();

        return {
            timestamp: new Date().toISOString(),
            level,
            message,
            data: {
                // merge priorty: global --> log --> instance (child) --> call
                ...globalContext,
                ...logContext,
                ...this.instanceContext,
                ...data,
                userAgent: navigator.userAgent,
                url: window.location.href,
            },
        };
    }
}

export function withLoggingContext(getContext: (instance: any) => ILogData) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = function (...args: any[]) {
            return LoggingContext.withAsyncContext(getContext(this), () => originalMethod.apply(this, args));
        };
        return descriptor;
    };
}

export const logger = new ContextLogger({
    apiEndpoint: process.env.MODE === "LOCAL" ? null : "/logs/client",
    batchSize: 10,
    batchTimeoutMs: 5000,
});
